
import {createRouter, createWebHashHistory} from "vue-router/dist/vue-router";
import main from "@/components/main";
export default createRouter({
    history:createWebHashHistory(),
    routes:[
        {path:'/:urlparams', component:main},
        {path:'/', component:main},
    ]
})
