<template>
  <div class="configs-error">
    <p>Invalid url path</p>
  </div>
</template>
<script>
export default {
  name: 'ConfigsError'
}
</script>
<style scoped>
.configs-error {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.configs-error p {
  font-size: 2rem;
  color: #000;
}
</style>
