import { createApp } from 'vue'
import App from './App.vue'
import http from "@/http";
import VueAxios from 'vue-axios'
import router from "@/router";
import {getApi} from "@/getApi";
import ConfigsError from "@/components/configs-error.vue";

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
getApi(params.business_id).then((tenantResponse) => {
    if(tenantResponse && tenantResponse.api_url){
        http.defaults.baseURL = tenantResponse.api_url;
        const app = createApp(App)
        app.config.globalProperties.businessId = tenantResponse.restaurant_id;
        app.use(router)
        app.use(VueAxios, http)
        app.mount('#app')
        if(window.self !== window.top){
            window.parent.postMessage({action: 'test'}, '*');
        }
    }else{
        const app = createApp(ConfigsError)
        app.use(router)
        app.use(VueAxios, http)
        app.mount('#app')
    }
});
