<template>
  <div
    class="main"
    v-if="business_id && club_id"
    :style="{ 'background-image': startbgImage, fontFamily: businessFont ,'align-items':'inherit'}"
  >

    <div class="backdrop"></div>
    <form
      class="form-site"
      v-if="status === 'registered'"
      @submit.prevent="goBack"
      :style="{ fontFamily: businessFont, color: style.colors.text }"
    >
      <a :style="{ color: style.colors.background, fontFamily: businessFont }"
      > איזה כיף!
        הבקשה שלך נקלטה אצלנו.
        <br/>
        <span :style="{display:'flex', 'justify-content':'center'}"> בקרוב, נהיה חברים..:)</span>
      </a>
    </form>

    <form
      class="form-site"
      v-if="status === 'registeredAlready'"
      @submit.prevent="goBack"
      :style="{ fontFamily: businessFont, color: style.colors.text }"
    >
      <a :style="{ fontFamily: businessFont, color: style.colors.text }"
      >המספר שהוזן כבר רשום כחבר מועדון</a
      >
      <button
        type="submit"
        value="חזרה"
        class="submit"
        :style="{
          fontFamily: businessFont,
          color: style.colors.buttons_text ? style.colors.buttons_text : ' #fff',
          background: style.colors.buttons
            ? style.colors.buttons
            : '-webkit-linear-gradient(1deg,rgba(242, 224, 121, 1) 0%,rgba(211, 174, 110, 1) 100%)!important',
        }"
      >
        חזור
      </button>
    </form>

    <!--      if user does not send dynamic his phone-->
    <form class="form-site" v-if="status === 'sendCode' && !this.clientSentPhone" @submit.prevent="sendCode"
          :style="{'flex':'6'}">

      <!-- כותרת הצטרפות -->
      <div :style="{'margin-bottom':'30px'}">
        <span :style="{display:'flex', 'justify-content':'center'}">אפשר להציע לכם חברות?</span>
        <br/>
        <span v-text="' מצטרפים למועדון ' +businessName +' וניהנים מהטבות בלעדיות '"></span>

      </div>
      <input
        class="input"
        name="Phone Number"
        v-model="phonenumber"
        type="tel"
        placeholder="טלפון"
        :style="{
          fontFamily: businessFont,
          color: style.colors.text ? style.colors.text : ' black ',
          background: style.colors.input_background
            ? style.colors.input_background
            : '#f5f5f5',
        }"
      />
      <button
        type="submit"
        value="שלח קוד"
        class="submit brand-primary-bg"
        :style="{
          fontFamily: businessFont,
          color: style.colors.buttons_text ? style.colors.buttons_text : ' #fff',
          background: style.colors.buttons
            ? style.colors.buttons
            : '-webkit-linear-gradient(1deg,rgba(242, 224, 121, 1) 0%,rgba(211, 174, 110, 1) 100%)!important',
        }"
      >
        שלח קוד
      </button>
      <router-link :to="loginRoute"/>
    </form>

    <!--if user send dynamic his phone-->
    <form class="form-site" v-if="status === 'sendCode' && this.clientSentPhone" @submit.prevent="sendCode"
          :style="{'flex':'6'}">
      <!-- כותרת הצטרפות -->
      <div :style="{'margin-bottom':'30px'}">
        <span :style="{display:'flex', 'justify-content':'center'}">אפשר להציע לכם חברות?</span>
        <br/>
        <span v-text="' מצטרפים למועדון ' +businessName +' וניהנים מהטבות בלעדיות '"></span>
      </div>
      <input
        class="input"
        type="tel"
        name="Phone Number"
        v-model="phonenumber"
        placeholder="טלפון"
        :style="{
          fontFamily: businessFont,
          color: style.colors.text ? style.colors.text : ' black ',
          background: style.colors.input_background
            ? style.colors.input_background
            : '#f5f5f5',
        }"
      />
      <button
        type="submit"
        value="המשך "
        class="submit"
        :style="{
          fontFamily: businessFont,
          color: style.colors.buttons_text ? style.colors.buttons_text : ' #fff',
          background: style.colors.buttons
            ? style.colors.buttons
            : '-webkit-linear-gradient(1deg,rgba(242, 224, 121, 1) 0%,rgba(211, 174, 110, 1) 100%)!important',
        }"
      >
        המשך
      </button>

    </form>
    <!-- logo of business -->
    <div v-if="status === 'sendCode' " class="header"
         :style="{'align-items':'flex-start'  ,
              'flex':'0',
            'margin': '0px',
            'z-index': '2'}">
      <img src="" id="logoBusinessImage" height="60"/>
    </div>
    <form
      class="form-site"
      v-if="status === 'enterCode'"
      @submit.prevent="enterCode"
      :style="{
        fontFamily: businessFont,
        color: style.colors.text ? style.colors.text : 'black',
      }"
    >
      <input
        class="input"
        type="tel"
        name="code"
        v-model="code"
        placeholder="קוד..."
        :style="{
          fontFamily: businessFont,
          color: 'black',
          background: style.colors.input_background
            ? style.colors.input_background
            : '#f5f5f5',
        }"
      />
      <a class="error" v-if="error">{{ error }}</a>
      <a type="submit" @click="goBack" :style="{ color: 'white' }">לא קיבלת קוד?</a>
      <button
        type="submit"
        value="אימות"
        class="submit"
        @click.prevent="enterCode"
        :style="{
          fontFamily: businessFont,
          color: style.colors.buttons_text ? style.colors.buttons_text : ' #fff',
          background: style.colors.buttons
            ? style.colors.buttons
            : '-webkit-linear-gradient(1deg,rgba(242, 224, 121, 1) 0%,rgba(211, 174, 110, 1) 100%)!important',
        }"
      >
        אימות
      </button>
    </form>
    <!-- דף הרשמה הראשי -->
    <form class="form-site" id="form-site" v-if="status === 'register'" @submit.prevent="register">
      <input
        required
        class="input"
        v-model="phonenumber"
        :disabled="true"
        :placeholder="this.phonenumber"
        type="tel"
        :style="{
          fontFamily: businessFont,
          color: style.colors.text ? style.colors.text : ' black',
          background: style.colors.input_background
            ? style.colors.input_background
            : '#f5f5f5',
        }"
      />
      <input
        class="input"
        name="firstName"
        id="firstName"
        v-model="firstName"
        placeholder="שם פרטי"
        :style="{
          fontFamily: businessFont,
          background: style.colors.input_background
            ? style.colors.input_background
            : '#f5f5f5',
        }"
      />
      <input
        class="input"
        id="lastname"
        name="lastname"
        v-model="lastName"
        placeholder="שם משפחה"
        type="text"
        :style="{
          fontFamily: businessFont,
          background: style.colors.input_background
            ? style.colors.input_background
            : '#f5f5f5',
        }"
      />
      <input
        class="input"
        id="mail"
        name="mail"
        v-model="email"
        placeholder="דוא''ל"
        type="text"
        :style="{
          fontFamily: businessFont,
          background: style.colors.input_background
            ? style.colors.input_background
            : '#f5f5f5',
        }"
      />
      <label for="birthday" :style="{ color: 'white' }">יום הולדת</label>
      <input
        type="text"
        id="dateFormat"
        v-model="this.birthday"
        :style="{ width: '100%', direction: 'ltr', 'text-align': 'center' }"
      />
      <label
        for="happyday"
        :style="{ color: 'white', fontFamily: style.fonts.normal_ttf }"
      >יום שמח</label
      >
      <input
        type="text"
        id="dateFormat2"
        v-model="this.happyday"
        :style="{ width: '100%', direction: 'ltr', 'text-align': 'center' }"
      />

      <select
        ref="branchSelect"
        class="input"
        v-model="favorite_branch"
        :style="{
          fontFamily: businessFont,
          background: style.colors.input_background
            ? style.colors.input_background
            : '#f5f5f5',
        }"
      >
        <option :value="null">סניף מועדף</option>
        <option v-for="branch in branches" :key="branch.id" :value="branch.id">
          {{ branch.name }}
          <template v-if="branch.address"> - {{ branch.address }}</template>
        </option>
      </select>

      <div class="genders">
        <div class="radio-item" v-for="(gender, index) in genders" :key="index">
          <input
            required
            :id="gender.code"
            type="radio"
            :value="gender.code"
            v-model="selGender"
            @click="selectedGender(gender.code)"
            :style="{
              fontFamily: businessFont,
              border: '2px ,solid, white',
              color: style.colors.buttons_text,
              background: style.colors.background,
            }"
          />
          <label
            :for="gender.code"
            :style="{
              fontFamily: businessFont,
              border: '2px ,solid' + style.colors.buttons_text,
              color: style.colors.buttons_text,
            }"
          >{{ gender.description }}</label
          >
        </div>
      </div>

      <!-- דיוור -->
      <div
        :style="{ color: 'white', fontFamily: businessFont, display: 'flex' , 'margin-top':'5px', 'margin-bottom':'5px'}">
        <input

          :value=" agreeMailing"
          v-model="agreeMailing"
          @click="(agreeMailing=!agreeMailing)"
          type="checkbox"
          id="approveMailing"
          class="check"
          name="approveMailing"
        />
        <label
          for="approveMailing"
          :style="{'margin-left':'8px'}"
        ></label>
        <span
          @click="(agreeMailing=!agreeMailing)"
          :style="{
            'margin-top':'5px'
          }"
        >
          מאשר/ת דיוור
      </span>
      </div>
      <div :style="{ color: 'white', fontFamily: businessFont, display: 'flex' }">
        <!-- תקנון הסכמה -->
        <input
          :value=" agreeTerms"
          v-model="agreeTerms"
          @click="agreeTerms=!agreeTerms"
          type="checkbox"
          id="approve"
          class="check"
          name="approve"
        />
        <label
          for="approve"
          :style="{
            'margin-top': '-2px',
            'text-align': 'center',
            'margin-left': '7px',
          }"
        ></label>
        <span
          @click="agreeTerms=!agreeTerms"
          :style="{
            'margin-top':'5px'
          }"
        >
            קראתי ואני מאשר/ת את
      </span>
        <a
          :href="
            'https://files.geteat.co.il/atmos/' + this.business_id + '/' + this.terms
          "
          target="_blank"
          :style="{
            color: style.colors.primary ? style.colors.primary : 'rgb(203, 172, 60)',
            'margin-inline-start': '5px',
            'margin-top':'5px'
          }"
        >התקנון
        </a>

      </div>
      <p :style="{   color: 'red' , 'font-weight':'bold',  }" id="error-msg"></p>
      <button
        type="submit"
        class="submit"
        :style="{
          fontFamily: businessFont,
          color: style.colors.buttons_text ? style.colors.buttons_text : ' #fff',
          background: style.colors.buttons
            ? style.colors.buttons
            : '-webkit-linear-gradient(1deg,rgba(242, 224, 121, 1) 0%,rgba(211, 174, 110, 1) 100%)!important',
        }"
      >
        הרשמה
      </button>

    </form>
  </div>
</template>
<script>
import "@vuepic/vue-datepicker/dist/main.css";
import dayjs from "dayjs";
import {getCurrentInstance, ref} from "vue";
import axios from "axios";

function convertDateFormat(inputDate) {
  if(inputDate){
    const parts = inputDate.split("-");
    const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    return formattedDate;
  }
  return '';
}

export default {
  setup() {
    const relativeTime = require("dayjs/plugin/relativeTime");
    dayjs.extend(relativeTime);
    const date = ref(new Date());
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    let phonenumber, clientSentPhone, club_id, business_id, status, session_key;

    if (params.phone) {
      phonenumber = params.phone;
      clientSentPhone = true;
    }
    if (params.club_id) {
      club_id = params.club_id;
    }

    const {proxy} = getCurrentInstance();
    if (proxy.businessId) {
      business_id = proxy.businessId;
    } else if (params.business_id) {
      business_id = params.business_id;
    }

    if(params.session_key){
      session_key = params.session_key;
    }

    if(params.session_key && params.phone){
      status = "register";
    }else{
      status = "sendCode";
    }

    if (!business_id) {
      business_id = 1;
    }
    if (!club_id) {
      club_id = 1;
    }

    return {
      date,
      urlParams: params,
      phonenumber,
      clientSentPhone,
      club_id,
      business_id,
      status,
      session_key
    };
  },
  updated() {
    const dateElement = document.querySelector("#dateFormat");
    if (dateElement) {
      dateElement.addEventListener("input", function () {
        var dateValue = dateElement.value;
        if (/\D$/.test(dateValue)) {
          dateValue = dateValue.substr(0, dateValue.length - 3);
        }
        dateValue = dateValue.replaceAll(" ", "");
        var arr = dateValue.split("/");

        if (arr[0]) {
          arr[0] =
            Number(arr[0]) > 3 && Number(arr[0]) < 10 && arr[0].length == 1
              ? "0" + arr[0]
              : arr[0];
          if (Number(arr[0]) > 31) {
            arr[0] = arr[0].substr(1, 2);
            arr[0] =
              Number(arr[0]) > 3 && Number(arr[0]) < 10 && arr[0].length == 1
                ? "0" + arr[0]
                : arr[0];
          }
        }
        if (arr[1]) {
          arr[1] =
            Number(arr[1]) > 1 && Number(arr[1]) < 10 && arr[1].length == 1
              ? "0" + arr[1]
              : arr[1];
          if (Number(arr[1]) > 12) {
            arr[1] = arr[1].substr(1, 2);
            arr[1] =
              Number(arr[1]) > 1 && Number(arr[1]) < 10 && arr[1].length == 1
                ? "0" + arr[1]
                : arr[1];
          }
        }
        var result = arr.map(function (val, index) {
          return val.length == 2 && index < 2 ? val + " / " : val;
        });

        dateElement.value = result.join("").substr(0, 14);
      });

      dateElement.addEventListener("blur", function () {
        dateElement.value = dateElement.value.replaceAll(" ", " ");
      });
    }
    const dateElement2 = document.querySelector("#dateFormat2");
    if (dateElement2) {
      dateElement2.addEventListener("input", function () {
        var dateValue = dateElement2.value;
        if (/\D$/.test(dateValue)) {
          dateValue = dateValue.substr(0, dateValue.length - 3);
        }
        dateValue = dateValue.replaceAll(" ", "");
        var arr = dateValue.split("/");

        if (arr[0]) {
          arr[0] =
            Number(arr[0]) > 3 && Number(arr[0]) < 10 && arr[0].length == 1
              ? "0" + arr[0]
              : arr[0];
          if (Number(arr[0]) > 31) {
            arr[0] = arr[0].substr(1, 2);
            arr[0] =
              Number(arr[0]) > 3 && Number(arr[0]) < 10 && arr[0].length == 1
                ? "0" + arr[0]
                : arr[0];
          }
        }
        if (arr[1]) {
          arr[1] =
            Number(arr[1]) > 1 && Number(arr[1]) < 10 && arr[1].length == 1
              ? "0" + arr[1]
              : arr[1];
          if (Number(arr[1]) > 12) {
            arr[1] = arr[1].substr(1, 2);
            arr[1] =
              Number(arr[1]) > 1 && Number(arr[1]) < 10 && arr[1].length == 1
                ? "0" + arr[1]
                : arr[1];
          }
        }
        var result = arr.map(function (val, index) {
          return val.length == 2 && index < 2 ? val + " / " : val;
        });
        dateElement2.value = result.join("").substr(0, 14);
      });
      dateElement2.addEventListener("blur", function () {
        dateElement2.value = dateElement2.value.replaceAll(" ", " ");
      });
    }
    if (document.getElementById('logoBusinessImage')) {
      if(this.businessLogo){
        document.getElementById('logoBusinessImage').src = this.businessLogo.slice(5, this.businessLogo.length - 2)
      }
      //document.getElementById('logoBusinessImage').height = '40px';
    }
  },
  async mounted() {
    await this.showingRestaurantPageByParams();
    this.stylecss = this.style.brand_css
  },
  data() {
    return {
      useSnippet: true,
      statuses: [
        {
          code: 0,
          description: "phone",
        },
        {
          code: 1,
          description: "validate",
        },
        {
          code: 2,
          description: "registration",
        },
        {
          code: 3,
          description: "registered",
        },
      ],
      formatdate: null,
      formathappyDay: null,
      terms: "",
      businessName: "",
      businessLogo: null,
      error: null,
      agreeTerms: false,
      agreeMailing: false,
      firstName: "",
      text: "",
      id: "",
      selGender: 0,
      city: {
        id: "",
        name: null,
        name_en: "",
      },
      cities: [],
      streets: [],
      street: {
        id: "",
        name: null,
        name_en: "",
      },
      genders: [
        {
          code: 0,
          description: "זכר",
        },
        {
          code: 1,
          description: "נקבה",
        },
        {
          code: 2,
          description: "אחר",
        },
      ],
      apartment: null,
      floor: null,
      birthday: null,
      happyday: null,
      stylecss: '',
      home: null,
      lastName: "",
      email: "",
      code: "",
      temp_key: "",
      businessFont: "Ploni",
      startbgImage: "",
      style: {
        restaurant_id: 0,
        restaurant_name: "",
        images: {
          loader: "",
          logo: "",
          icon: null,
          start_background_desktop: "",
          start_background_mobile: "",
          start_background_kiosk: null,
          menu_background_desktop: "",
          menu_background_mobile: "",
          menu_background_kiosk: null,
          delivery_image: "",
          takeaway_image: "",
          sitting_image: null,
          credit_card_image: null,
          cash_image: null,
          members_club_image: null,
          logo_horizontal: "",
        },
        fonts: {
          normal_eot: null,
          normal_woff: null,
          normal_ttf: null,
          bold_eot: null,
          bold_woff: null,
          bold_ttf: null,
        },
        radiuses: {
          cards: "12px",
          buttons: "30px",
          inputs: "30px",
        },
        colors: {
          background: "",
          primary: "",
          secondary: "",
          titles: "",
          text: "",
          buttons: "",
          buttons_text: "",
          buttons_close: "",
          input_background: "",
          danger: "",
          success: "",
          mobile_header: "",
          mobile_burger: "",
        },
        brand_css: "",
        custom_css_desktop: "",
        custom_css_mobile: "",
        custom_css_kiosk: "",
      },
      branches: [],
      favorite_branch: null,
    };
  },
  computed: {
    loginRoute() {
      return `/${this.urlParams}`;
    }
  },
  methods: {
    async appendFile() {
      let style = document.createElement('style');
      const url = `https://s3-eu-west-1.amazonaws.com/files.geteat.co.il/atmos/${this.business_id}/${this.stylecss}`
      const {data} = await axios.get(url)
      if (data) {
        const css = data;
        style.appendChild(document.createTextNode(css));
        document.head.appendChild(style)
      }
    },
    async showingRestaurantPageByParams() {
      const mobile = document.body.offsetWidth < 767;
      let form = new FormData();
      form.append("restaurant_id", this.business_id);
      this.$http.post("restaurants/getBranchesList", form).then((res) => {
        this.branches = res.data.result;
      }).catch((err) => {
        console.log(err);
      });
      this.$http.post("restaurants/getBrand", form)
        .then(async (res) => {
          this.style = res.data.result;
          this.stylecss = this.style.brand_css
          this.appendFile()
          if (mobile) {
            const elCustomCssMobile = document.createElement("style");
            elCustomCssMobile.innerText = this.style.custom_css_mobile;
            document.head.appendChild(elCustomCssMobile);
            if (this.style.images.start_background_mobile) {
              this.businessLogo = `url("https://files.geteat.co.il/atmos/${this.business_id}/images/xxl-${this.style.images.logo}")`;
              this.startbgImage = `url("https://files.geteat.co.il/atmos/${this.business_id}/images/xxl-${this.style.images.start_background_mobile}")`;
            }
            if (this.style.images.start_background_mobile === null) {
              this.businessLogo = `url("https://files.geteat.co.il/atmos/${this.business_id}/images/xxl-${this.style.images.logo}")`;
              this.startbgImage = `url("https://m.media-amazon.com/images/I/51UW1849rJL._AC_SX425_.jpg")`;
            }
            if (this.style.fonts.normal_ttf !== null) {
              this.businessFont = "BrandFont";
            }
          } else {
            const elCustomCssDesktop = document.createElement("style");
            elCustomCssDesktop.innerText = this.style.custom_css_desktop;
            document.head.appendChild(elCustomCssDesktop);
            this.businessLogo = `url("https://files.geteat.co.il/atmos/${this.business_id}/images/xxl-${this.style.images.logo}")`;
            this.startbgImage = `url("https://files.geteat.co.il/atmos/${this.business_id}/images/xl-${this.style.images.start_background_desktop}")`;
            if (this.style.fonts.normal_ttf !== null) {
              this.businessFont = "BrandFont";
            }
          }
          //style for radio buttons from business
          const styles = `       .radio-item label:before {
                                content: " ";
                                  display: inline-block;
                                  position: relative;
                                  top: 5px;
                                  right: -6px;
                                  margin: 0 5px 0 0;
                                  width: 20px;
                                  height: 20px;
                                  border-radius: 11px;
                                  border: 2px solid ${this.style.colors.background}  ;
                                  background-color: transparent;
                                }
                                  .radio-item label {
                                    color: ${this.style.colors.background};
                                    font-weight: normal;
                                    padding-right: 6px;
                                  }
                              .radio-item input[type=radio]:checked + label:after {
                                    border-radius: 11px;
                                    width: 12px;
                                    height: 12px;
                                    position: absolute;
                                    top: 11px;
                                    right: 17px;
                                    content: " ";
                                    display: block;
                                    background:  ${this.style.colors.background};}
                                    
                                    input[type=checkbox]:checked 
                                     {    
                                    color: ${this.style.colors.background};
                                    background:  ${this.style.colors.background};}`;
          const styleSheet = document.createElement("style");
          styleSheet.innerText = styles;
          document.head.appendChild(styleSheet);
          await this.getTerms();
        });
    },
    selectedGender(option) {
      this.selGender = option;
    },
    async getTerms() {
      const mobile = document.body.offsetWidth < 767;
      const {data} = await this.$http.post(
        "restaurants/getBusiness",
        {
          restaurant_id: this.business_id,
        }
      );
      this.businessName = data.result.name
      this.businessLogo = data.result.logo


      this.startbgImage = this.style.images.loader;
      if (data) {
        if (mobile) {
          this.businessLogo = `url("https://files.geteat.co.il/atmos/${this.business_id}/images/xxl-${this.style.images.logo}")`;
          this.startbgImage = this.style.images.start_background_mobile;
        } else {
          this.businessLogo = `url("https://files.geteat.co.il/atmos/${this.business_id}/images/xxl-${this.style.images.logo}")`;
          this.startbgImage = this.style.images.start_background_desktop;
        }
        this.terms = data.result.terms;
      } else {
        this.terms = null;
      }
    },
    async sendCode() {
      const mobile = document.body.offsetWidth < 767;
      const {data} = await this.$http.post(
        "clubauth/sendValidationCode",
        {
          club_id: this.club_id,
          phone: this.phonenumber,
        }
      );
      this.startbgImage = this.style.images.loader;
      if (data) {
        this.temp_key = data.result.tmp_key;
        if (data.success) {
          if (mobile) {

            this.startbgImage = this.style.images.start_background_mobile;
          } else {
            this.businessLogo = this.style.images.logo;
            this.startbgImage = this.style.images.start_background_desktop;
          }
          this.status = "enterCode";
        }
      } else {
        if (mobile) {

          this.startbgImage = this.style.images.start_background_mobile;
        } else {

          this.startbgImage = this.style.images.start_background_desktop;
        }
      }
    },
    async updateItem(text) {
      let citiesQuery = await this.$http.post("addresses/citiesAutoComplete", {
        prefix: text,
      });

      if (citiesQuery && citiesQuery.status === 200 && citiesQuery.data.success)
        this.cities = citiesQuery.data.result;

      const streetsQuery = await this.$http.post("addresses/streetsAutoComplete", {
        prefix: text,
      });

      if (streetsQuery && streetsQuery.status === 200 && streetsQuery.data.success)
        this.streets = streetsQuery.data.result;
    },
    goBack() {
      this.status = "sendCode";
      this.error = null;
    },
    async enterCode() {
      const mobile = document.body.offsetWidth < 767;
      this.$http
        .post("clubauth/checkValidationCode", {
          tmp_key: this.temp_key,
          phone: this.phonenumber,
          code: this.code,
        })
        .then((res) => {

          this.startbgImage = this.style.images.loader;
          if (res.data.success && !res.data.result.is_registered) {
            this.session_key = res.data.result.session_key;
            this.status = "register";
            if (mobile) {

              this.startbgImage = this.style.images.start_background_mobile;
            } else {

              this.startbgImage = this.style.images.start_background_desktop;
            }
          }
          if (res.data.success && res.data.result.is_registered) {
            this.status = "registeredAlready";
            if (mobile) {

              this.startbgImage = this.style.images.start_background_mobile;
            } else {

              this.startbgImage = this.style.images.start_background_desktop;
            }
          } else {
            this.error = "הקוד שהוזן שגוי";
            if (mobile) {

              this.startbgImage = this.style.images.start_background_mobile;
            } else {

              this.startbgImage = this.style.images.start_background_desktop;
            }
          }
        });
    },
    async register() {
      if (this.agreeTerms === false) {
        document.getElementById('error-msg').textContent = "אנא אשר את התקנון";
      }


      if (this.agreeTerms === true) {
        if (!this.email.includes('@') || !this.email.includes('.')) {
          document.getElementById('error-msg').textContent = "דוא''ל אינו תקין";
          document.getElementById('mail').style.border = "3px solid red";
        } else {
          document.getElementById('error-msg').textContent = "";
          document.getElementById('mail').style.border = "1px solid transparent";
        }
      }
      if (document.getElementById('firstName').value === "") {
        document.getElementById('firstName').style.border = "3px solid red";
      } else {
        document.getElementById('firstName').style.border = "1px solid transparent";
      }
      if (document.getElementById('lastname').value === "") {
        document.getElementById('lastname').style.border = "3px solid red";
      } else {
        document.getElementById('lastname').style.border = "1px solid transparent";
      }
      if (document.getElementById('dateFormat').value === "") {
        document.getElementById('dateFormat').style.border = "3px solid red";
      } else {
        document.getElementById('dateFormat').style.border = "1px solid transparent";
      }
      if(this.favorite_branch == null){
        this.$refs.branchSelect.style.border = "3px solid red";
        document.getElementById('error-msg').textContent = "יש לבחור סניף מועדף";
      }else{
        document.getElementById('error-msg').textContent = "";
        this.$refs.branchSelect.style.border = "1px solid transparent";
      }
      if (this.email.length > 0) {
        if (this.email.includes('@') && this.email.includes('.')) {
          if (document.getElementById('firstName').value !== "" && document.getElementById('lastname').value !== "" && document.getElementById('dateFormat').value !== "" && this.agreeTerms === true) {
            let birthday = this.birthday.replaceAll(" ", "");
            birthday = birthday.replaceAll("/", "-");
            let happyday = this.happyday?.replaceAll(" ", "");
            happyday = happyday?.replaceAll("/", "-");
            const mobile = document.body.offsetWidth < 767;
            const body = {
              session_key: this.session_key,
              club_id: this.club_id,
              first_name: this.firstName,
              last_name: this.lastName,
              email: this.email,
              gender: this.selGender,
              phone: this.phonenumber,
              birthdate: convertDateFormat(birthday),
              happy_day: convertDateFormat(happyday),
              terms_agreement: true,
              subscribe: this.agreeMailing

            };
            const regRes = await this.$http.post("clubauth/register", body);
            if(regRes.data && regRes.data.success){
              this.reportParentAboutRegistrationSuccess(this.session_key);
            }else{
              this.reportParentAboutRegistrationFailure();
            }
            this.startbgImage = this.style.images.loader;
            this.businessLogo = this.style.images.logo;
            this.status = "registered";
            if (mobile) {
              this.status = "registered";
              this.businessLogo = this.style.images.logo;
              this.startbgImage = this.style.images.start_background_mobile;
            } else {
              this.status = "registered";
              this.businessLogo = this.style.images.logo;
              this.startbgImage = this.style.images.start_background_desktop;
            }
          }
        }
      } else {
        if (document.getElementById('firstName').value != "" && document.getElementById('lastname').value != "" && document.getElementById('dateFormat').value != "" && this.agreeTerms == true) {
          let birthday = this.birthday.replaceAll(" ", "");
          birthday = birthday.replaceAll("/", "-");
          let happyday = this.happyday?.replaceAll(" ", "");
          happyday = happyday?.replaceAll("/", "-");
          const mobile = document.body.offsetWidth < 767;
          const body = {
            session_key: this.session_key,
            club_id: this.club_id,
            first_name: this.firstName,
            last_name: this.lastName,
            gender: this.selGender,
            phone: this.phonenumber,
            birthdate: convertDateFormat(birthday),
            happy_day: convertDateFormat(happyday),
            terms_agreement: true,
            subscribe: this.agreeMailing
          };
          const regRes = await this.$http.post("clubauth/register", body);
          if(regRes.data && regRes.data.success){
            this.reportParentAboutRegistrationSuccess(this.session_key);
          }else{
            this.reportParentAboutRegistrationFailure();
          }
          this.startbgImage = this.style.images.loader;
          this.businessLogo = this.style.images.logo;
          this.status = "registered";
          if (mobile) {
            this.status = "registered";
            this.businessLogo = this.style.images.logo;
            this.startbgImage = this.style.images.start_background_mobile;
          } else {
            this.status = "registered";
            this.businessLogo = this.style.images.logo;
            this.startbgImage = this.style.images.start_background_desktop;
          }
        }
      }
    },
    reportParentAboutRegistrationSuccess(sessionKey){
      if(window.self !== window.top){
        window.parent.postMessage({action: 'register-success', sessionKey}, '*');
      }
    },
    reportParentAboutRegistrationFailure(){
      if(window.self !== window.top){
        window.parent.postMessage({action: 'register-fail'}, '*');
      }
    },
    reportParentAboutClose(){
      if(window.self !== window.top){
        window.parent.postMessage({action: 'close'}, '*');
      }
    },
  },
};
</script>

