<style>
@import '@/assets/main.css';
</style>

<template>
  <router-view> </router-view>
</template>

<script>

export default {
  name: "App",
}
</script>
