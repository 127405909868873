import axios from "axios";
//import store from "@/store";

const http = axios.create();
http.interceptors.request.use((config) => {
    // add authorization header to requests
    /*if(store.state.accessToken){
        config.headers.Authorization = store.state.accessToken
    }*/
    config.data = new URLSearchParams(config.data)
    return config
},(error) => {

    return Promise.reject(error);
})

export default http
